<template>
    <div class="add-new-travelling-order" v-if="receipt">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.receiptsUpdate')"
            :title="'Update receipt - ' + receipt.referenceNumber"
            @submitPressed="submitPressed"
        />
        <receipt-form
            v-if="receipt"
            :receiptObject="receipt"
            :action="action"
            @clearAction="action = null"
        />
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import ReceiptForm from "./components/ReceiptForm.vue";

export default {
    components: {
        TitleBar,
        ReceiptForm,
    },
    data() {
        return {
            receipt: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$Receipts.getResource({id}).then((response) => {
                this.receipt = response.data;
                if ('closedBy' in this.receipt) {
                    this.receipt.closed = true
                }
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
